<template>
<div class="container overflow-hidden">
        <!-- Nav com breadcrumb -->
        <div class="nav-rotas-anteriores pt-5 px-3">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Categorias</li>
                </ol>
            </nav>
        </div>

        <h1>Categorias:</h1>

    <div class="row" v-for="area in areas" :key="area.titulo">
        <h2>{{area.titulo}}</h2>
        <br>

        <div class="row mt-4" v-for="subtitulo in area.subtitulos" :key="subtitulo.titulo">
            <h5 style="text-align: left">{{subtitulo.titulo}}</h5>
            <hr>
            <div class="col-12 col-md-6 col-lg-4 p-2 p-lg-3" v-for="assunto in subtitulo.assuntos" :key="assunto" >
                <div class="categoria-card pl-3">
                    <div class="card-text">{{assunto}}</div>
                </div>
            </div>
        </div>
        
    </div>




    </div>
</template>

<script>
import tags_lista from '@/store/categorias.js'

export default {
  name: 'menuCategorias',
  props: {
      n_resultados: Number,
      btn_ver_mais: Boolean
  },
  data() {
      return {
        tags: tags_lista.lista, // Pega a lista de tags de um arquivo js
        areas: 
        [
            {
                titulo: "CIÊNCIAS EXATAS E DA TERRA",
                subtitulos:[
                    {
                        titulo: "MATEMÁTICA",
                        assuntos: ["ALGEBRA", "CONJUNTOS", "LÓGICA MATEMÁTICA", "TEORIA DOS NÚMEROS", "GRUPO DE ÁLGEBRA NÃO-COMUTATIVA", "ÁLGEBRA COMUTATIVA", "GEOMETRIA ALGÉBRICA", "ANÁLISE", "ANÁLISE COMPLEXA", "ANÁLISE FUNCIONAL", "ANÁLISE FUNCIONAL NÃO-LINEAR", "EQUAÇÕES DIFERENCIAIS ORDINÁRIAS", "EQUAÇÕES DIFERENCIAIS PARCIAIS", "EQUAÇÕES DIFERENCIAIS FUNCIONAIS", "GEOMETRIA E TOPOLOGIA", "GEOMETRIA DIFERÊNCIAL", "TOPOLOGIA ALGÉBRICA", "TOPOLOGIA DAS VARIEDADES", "SISTEMAS DINÂMICOS", "TEORIA DAS SINGULARIDADES E TEORIA DAS CATÁSTROFES", "TEORIA DAS FOLHEAÇÕES", "MATEMÁTICA APLICADA", "FÍSICA MATEMÁTICA", "ANÁLISE NUMÉRICA", "MATEMÁTICA DISCRETA E COMBINATÓRIA"]
                    },
                    {
                        titulo: "PROBABILIDADE E ESTATÍSTICA",
                        assuntos: ["TEORIA GERAL E FUNDAMENTOS DA PROBABILIDADE", "TEORIA GERAL E PROCESSOS ESTOCÁSTICOS", "TEOREMAS DE LIMITE", "PROCESSOS MARKOVIANOS", "ANÁLISE ESTOCÁSTICA", "PROCESSOS ESTOCÁSTICOS ESPECIAIS", "ESTATÍSTICA", "FUNDAMENTOS DA ESTATÍSTICA", "INFERÊNCIA PARAMÉTRICA", "INFERÊNCIA NÃO-PARAMÉTRICA", "INFERÊNCIA EM PROCESSOS ESTOCÁSTICOS", "ANÁLISE MULTIVARIADA", "REGRESSÃO E CORRELAÇÃO", "PLANEJAMENTO DE EXPERIMENTOS", "ANÁLISE DE DADOS", "PROBABILIDADE E ESTATÍSTICA APLICADAS"]
                    },
                    {
                        titulo: "CIÊNCIA DA COMPUTAÇÃO",
                        assuntos: ["TEORIA DA COMPUTAÇÃO", "COMPUTABILIDADE E MODELOS DE COMPUTAÇÃO", "LINGUAGEM FORMAIS E AUTÔMATOS", "ANÁLISE DE ALGORÍTMOS E COMPLEXIDADE DE COMPUTAÇÃO", "LÓGICAS E SEMÂNTICA DE PROGRAMAS", "MATEMÁTICA DA COMPUTAÇÃO", "MATEMÁTICA SIMBÓLICA", "MODELOS ANALÍTICOS E DE SIMULAÇÃO", "METODOLOGIA E TÉCNICAS DA COMPUTAÇÃO", "LINGUAGENS DE PROGRAMAÇÃO", "ENGENHARIA DE SOFTWARE", "BANCO DE DADOS", "SISTEMAS DE INFORMAÇÃO", "PROCESSAMENTO GRÁFICO (GRAPHICS)", "SISTEMA DE COMPUTAÇÃO", "HARDWARE", "ARQUITETURA DE SISTEMAS DE COMPUTAÇÃO", "SOFTWARE BÁSICO", "TELEINFORMÁTICA"]
                    }
                ]
            },
            {
                titulo: "ENGENHARIAS",
                subtitulos:[
                    {
                        titulo: "ENGENHARIA CIVIL",
                        assuntos: ["CONSTRUÇÃO CIVIL", "MATERIAIS E COMPONENTES DE CONSTRUÇÃO", "PROCESSOS CONSTRUTIVOS", "INSTALAÇÕES PREDIAIS", "ESTRUTURAS", "ESTRUTURAS DE CONCRETO", "ESTRUTURAS DE MADEIRAS", "ESTRUTURAS METÁLICAS", "MECÂNICA DAS ESTRUTURAS", "GEOTÉCNICA", "FUNDAÇÕES E ESCAVAÇÕES", "MECÂNICAS DAS ROCHAS", "MECÂNICA DOS SOLOS", "OBRAS DE TERRA E ENROCAMENTO", "PAVIMENTOS", "ENGENHARIA HIDRÁULICA", "HIDRÁULICA", "HIDROLOGIA", "INFRA-ESTRUTURA DE TRANSPORTES", "AEROPORTOS; PROJETO E CONSTRUÇÃO", "FERROVIAS; PROJETOS E CONSTRUÇÃO", "PORTOS E VIAS NAVEGÁVEIS; PROJETO E CONSTRUÇÃO", "RODOVIAS; PROJETO E CONSTRUÇÃO"]
                    },
                    {
                        titulo: "ENGENHARIA MECÂNICA",
                        assuntos: ["FENÔMENOS DE TRANSPORTES", "TRANSFERÊNCIA DE CALOR", "MECÂNICA DOS FLUÍDOS", "DINÂMICA DOS GASES", "PRINCÍPIOS VARIACIONAIS E MÉTODOS NUMÉRICOS", "ENGENHARIA TÉRMICA", "TERMODINÂMICA", "CONTROLE AMBIENTAL", "APROVEITAMENTO DA ENERGIA", "MECÂNICA DOS SÓLIDOS", "MECÂNICA DOS CORPOS SÓLIDOS, ELÁSTICOS E PLÁSTICOS", "DINÂMICA DOS CORPOS RÍGIDOS, ELÁSTICOS E PLÁSTICOS", "ANÁLISE DE TENSÕES", "TERMOELASTICIDADE", "PROJETOS DE MÁQUINAS", "TEORIA DOS MECANISMOS", "ESTÁTICA E DINÂMICA APLICADA", "ELEMENTOS DE MÁQUINAS", "FUNDAMENTOS GERAIS DE PROJETOS DAS MÁQUINAS", "MÁQUINAS, MOTORES E EQUIPAMENTOS", "MÉTODOS DE SÍNTESE E OTIMIZAÇÃO APLICADOS AO PROJ.  MECÂNICO", "CONTROLE DE SISTEMAS MECÂNICOS", "APROVEITAMENTO DE ENERGIA", "PROCESSOS DE FABRICAÇÃO", "MATRIZES E FERRAMENTAS", "MÁQUINAS DE USINAGEM E CONFORMAÇÃO", "CONTROLE NUMÉRICO", "ROBOTIZAÇÃO", "PROCESSOS DE FABRICAÇÃO, SELEÇÃO ECONÔMICA"]
                    },
                    {
                        titulo: "ENGENHARIA DE PRODUÇÃO",
                        assuntos: ["GERÊNCIA DE PRODUÇÃO", "PLANEJAMENTO DE INSTALAÇOES INDUSTRIAIS", "PLANEJAMENTO, PROJETO E CONTROLE DE SIST. DE PRODUÇÃO", "HIGIENE E SEGURANÇA DO TRABALHO", "SUPRIMENTOS", "GARANTIA DE CONTROLE DE QUALIDADE", "PESQUISA OPERACIONAL", "PROCESSOS ESTOCÁSTICOS E TEORIAS DAS FILAS", "PROGRAMAÇÃO LINEAR, NÃO-LINEAR, MISTA E DINÂMICA", "SÉRIES TEMPORAIS", "TEORIA DOS GRAFOS", "TEORIA DOS JOGOS", "ENGENHARIA DO PRODUTO", "ERGONOMIA", "METODOLOGIA DE PROJETO DO PRODUTO", "PROCESSOS DE TRABALHO", "GERÊNCIA DO PROJETO E DO PRODUTO", "DESENVOLVIMENTO DE PRODUTO", "ENGENHARIA ECONÔMICA", "ESTUDO DE MERCADO", "LOCALIZAÇÃO INDUSTRIAL", "ANÁLISE DE CUSTOS", "ECONOMIA DE TECNOLOGIA", "VIDA ECONÔMICA DOS EQUIPAMENTOS", "AVALIAÇÃO DE PROJETOS"]
                    }
                ]
            },
            {
                titulo: "CIÊNCIAS DA SAÚDE",
                subtitulos:[
                    {
                        titulo: "MEDICINA",
                        assuntos: ["CLÍNICA MÉDICA", "ANGIOLOGIA", "DERMATOLOGIA", "CANCEROLOGIA", "ENDOCRINOLOGIA", "CARDIOLOGIA", "GASTROENTEROLOGIA", "PNEUMOLOGIA", "NEFROLOGIA", "FISIATRIA", "MEDICINA LEGAL E DEONTOLOGIA", "ALERGOLOGIA E IMUNOLOGIA CLÍNICA", "HEMATOLOGIA", "NEUROLOGIA", "PEDIATRIA", "DOENÇAS INFECCIOSAS E PARASITÁRIAS", "REUMATOLOGIA", "SAÚDE MATERNO-INFANTIL", "PSIQUIATRIA", "ANATOMIA PATOLÓGICA E PATOLOGIA CLÍNICA", "RADIOLOGIA MÉDICA", "GINECOLOGIA E OBSTETRÍCIA", "OFTALMOLOGIA", "ORTOPEDIA", "CIRURGIA", "CIRURGIA PLÁSTICA E RESTAURADORA", "CIRURGIA OTORRINOLARINGOLOGIA", "CIRURGIA OFTALMOLÓGICA", "CIRURGIA CARDIOVASCULAR", "CIRURGIA TORÁXICA", "CIRURGIA GASTROENTEROLOGICA", "CIRURGIA PEDIÁTRICA", "NEUROCIRURGIA", "CIRURGIA UROLÓGICA", "CIRURGIA PROCTOLÓGICA", "CIRURGIA ORTOPÉDICA", "CIRURGIA TRAUMATOLÓGICA", "ANESTESIOLOGIA", "CIRURGIA EXPERIMENTAL", ]
                    },
                    {
                        titulo: "ODONTOLOGIA",
                        assuntos: ["CLÍNICA ODONTOLÓGICA", "CIRURGIA BUCO-MAXILO-FACIAL", "ORTODONTIA", "ODONTOPEDIATRIA", "PERIODONTIA", "ENDODONTIA", "RADIOLOGIA ODONTOLÓGICA", "ODONTOLOGIA SOCIAL E PREVENTIVA", "MATERIAIS ODONTOLÓGICOS"]
                    },
                    {
                        titulo: "ENFERMAGEM",
                        assuntos: ["ENFERMAGEM MÉDICO-CIRÚRGICA", "ENFERMAGEM OBSTÉTRICA", "ENFERMAGEM PEDIÁTRICA", "ENFERMAGEM PSIQUIÁTRICA", "ENFERMAGEM DE DOENÇAS CONTAGIOSAS", "ENFERMAGEM DE SAÚDE PÚBLICA"]
                    }
                ]
            },
            {
                titulo: "CIÊNCIAS SOCIAIS APLICADAS",
                subtitulos:[
                    {
                        titulo: "DIREITO",
                        assuntos: ["TEORIA DO DIREITO", "TEORIA GERAL DO DIREITO", "TEORIA GERAL DO PROCESSO", "TEORIA DO ESTADO", "HISTÓRIA DO DIREITO", "FILOSOFIA DO DIREITO", "LÓGICA JURÍDICA", "SOCIOLOGIA JURÍDICA", "ANTROPOLOGIA JURÍDICA", "DIREITO PÚBLICO", "DIREITO TRIBUTÁRIO", "DIREITO PENAL", "DIREITO PROCESSUAL PENAL", "DIREITO PROCESSUAL CIVIL", "DIREITO CONSTITUCIONAL", "DIREITO ADMINISTRATIVO", "DIREITO INTERNACIONAL PÚBLICO", "DIREITO PRIVADO", "DIREITO CIVIL", "DIREITO COMERCIAL", "DIREITO DO TRABALHO", "DIREITO INTERNACIONAL PRIVADO", "DIREITOS ESPECIAIS"]
                    },
                    {
                        titulo: "ADMINISTRAÇÃO",
                        assuntos: ["ADMINISTRAÇÃO DE EMPRESAS", "ADMINISTRAÇÃO DE PRODUÇÃO", "ADMINISTRAÇÃO FINANCEIRA", "MERCADOLOGIA", "NEGÓCIOS INTERNACIONAIS", "ADMINISTRAÇÃO DE RECURSOS HUMANOS", "ADMINISTRAÇÃO PÚBLICA", "CONTABILIDADE E FINANÇAS PÚBLICAS", "ORGANIZAÇÕES PÚBLICAS", "POLÍTICA E PLANEJAMENTO GOVERNAMENTAIS", "ADMINISTRAÇÃO DE PESSOAL", "ADMINISTRAÇÃO DE SETORES ESPECÍFICOS", "CIÊNCIAS CONTÁBEIS"]
                    },
                    {
                        titulo: "ECONOMIA",
                        assuntos: ["TEORIA ECONÔMICA", "ECONOMIA GERAL", "TEORIA GERAL DA ECONOMIA", "HISTÓRIA DO PENSAMENTO ECONÔMICO", "HISTÓRIA ECONÔMICA", "SISTEMAS ECONÔMICOS", "MÉTODOS QUANTITATIVOS EM ECONOMIA", "MÉTODOS E MODELOS MATEMÁT., ECONOMÉTRICOS E ESTATÍSTICOS", "ESTATÍSTICA SÓCIO-ECONÔMICA", "CONTABILIDADE NACIONAL", "ECONOMIA MATEMÁTICA", "ECONOMIA MONETÁRIA E FISCAL", "TEORIA MONETÁRIA E FINANCEIRA", "INSTITUIÇÕES MONETÁRIAS E FINANCEIRAS DO BRASIL", "FINANÇAS PÚBLICAS INTERNAS", "POLÍTICA FISCAL DO BRASIL", "CRESCIMENTO, FLUTUAÇÕES E PLANEJAMENTO ECONÔMICO", "CRESCIMENTO E DESENVOLVIMENTO ECONÔMICO", "TEORIA E POLÍTICA DE PLANEJAMENTO ECONÔMICO", "FLUTAÇÕES CICLÍCAS E PROJEÇÕES ECONÔMICAS", "INFLAÇÃO", "ECONOMIA INTERNACIONAL", "TEORIA DO COMÉRCIO INTERNACIONAL", "RELAÇÕES DO COMÉRCIO; POLÍT. COMERCIAL; INTEGRAÇÃO ECONÔMICA", "BALANÇO DE PAGAMENTO; FINANÇAS INTERNACIONAIS", "INVESTIMENTOS INTERNACIONAIS E AJUDA EXTERNA", "ECONOMIA DOS RECURSOS HUMANOS", "TREIN. E ALOCAÇÃO DE MÃO-DE-OBRA;OFERTA MÃO-DE-OBRA F. TRAB.", "MERCADO DE TRABALHO; POLÍTICA DO GOVERNO", "SINDICATOS, DISSÍDIOS COLET., RELAÇÕES DE EMPREGO(EMP./EMP)", "CAPITAL HUMANO", "DEMOGRAFIA ECONÔMICA", "ECONOMIA INDUSTRIAL", "ORGANIZAÇÃO INDUSTRIAL E ESTUDOS INDUSTRIAIS", "MUDANÇA TECNOLÓGICA", "ECONOMIA DO BEM-ESTAR SOCIAL", "ECONOMIA DOS PROGRAMAS DE BEM-ESTAR SOCIAL", "ECONOMIA DO CONSUMIDOR", "ECONOMIA REGIONAL E URBANA", "ECONOMIA REGIONAL", "ECONOMIA URBANA", "RENDA E TRIBUTAÇÃO", "ECONOMIAS AGRÁRIA E DOS RECURSOS NATURAIS", "ECONOMIA AGRÁRIA", "ECONOMIA DOS RECURSOS NATURAIS"]
                    }
                ]
            }
        ]
      }
  }
}


</script>

<style scoped>
a {
        color: #000;
        font-weight: 600;
        text-decoration: none;
    }
    .breadcrumb{
    background-color: transparent !important;
}
.categoria-card {
    background-color: #fff;
    height: 130%;
    box-shadow: rgb(233 233 235 / 70%) 0px 2px 10px 5px;
    border: 1px solid rgb(229, 229, 233); 
    font-size: 15px; 
    border-radius: 5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.categoria-card:hover {
    border: 1px solid #004588;
    cursor: pointer;
}
.card-icon{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 67px;
    padding: 0 15px 0 20px;
}
.card-text{
    font-weight: 100;
    color: #000;
    text-align: left;
}
@media screen and (min-width: 768px){
    .categoria-card {
      height: 130% ;
    }
}
</style>

